import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { retryBackoff, RetryBackoffConfig } from 'backoff-rxjs';
import { BehaviorSubject, map, shareReplay, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Bookmark } from '../interfaces/Bookmark';
import { FileMetada } from '../interfaces/FileMetadata';
import { DocumentDataModel } from '../models/document-data.model';
import { ProductionViewService } from './production-view.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private documentData = new BehaviorSubject<DocumentDataModel>(null);
  private documentDataIsNull = true;
  private readonly retryConfig: RetryBackoffConfig = {
    initialInterval: 1000,
    maxRetries: 3,
  };

  constructor(
    private productionViewService: ProductionViewService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) { }

  setProject(project: string) {
    this.productionViewService.project = project;
  }

  writeNewDocumentData(newDocumentData: DocumentDataModel) {
    this.documentData.next(newDocumentData);
    this.documentDataIsNull = false;
  }

  readDocumentData(raw = false) {

    const urlSegments = this.router.url.split('/').filter((segment) => segment !== '');

    const floderHash = urlSegments.at(-1);

    return this.productionViewService
      .getDocumentData(floderHash, raw)
      .pipe(tap((value) => this.writeNewDocumentData(value)))
  }

  getFolderHash() {
    return this.readDocumentData().pipe(map((data) => data.folderHash));
  }

  getDocumentImages() {
    return this.readDocumentData().pipe(
      map((data) => {
        return data.imagesList.length > 0
          ? data.imagesList.map((img) => img.imageHash)
          : data.tagsHistory.at(-1).at(-1).images;
      })
    );
  }

  getDocumentFiles() {
    return this.readDocumentData().pipe(
      map((data) => data.fileList.map((file) => file.fileHash))
    );
  }

  getFileMetadata(fileHash: string) {
    const url = `${environment.apiUrl}/Files/File?hash=${fileHash}`;

    return this.http.get<FileMetada>(url);
  }

  getBookmarks({ useArea = true, rawDocData = false } = {}) {
    return this.readDocumentData(rawDocData).pipe(
      switchMap((data) => {
        const url = useArea
          ? `${environment.portalURL}/api/BookmarksH?project=${data.project}&area=${data.area}`
          : `${environment.portalURL}/api/BookmarksH?project=${data.project}`;
        return this.http.get<Bookmark[]>(url);
      })
    );
  }

  sendDataEntryToQueueRelay(msg: any) {
    const url: string = `${environment.queueRelay}/api/QueueRelay/SendToController`;
    return this.http
      .post(url, msg, { observe: 'response' })
      .pipe(retryBackoff(this.retryConfig));
  }

  unlockDocument() {
    return this.getFolderHash().pipe(
      switchMap((folderHash) => {
        const url = `${environment.portalURL}/api/ManualProcess/UnlockEntry?folderHash=${folderHash}`;
        return this.http.post(url, null, { observe: 'response' });
      })
    );
  }
}
